import {type EventLog, TagType} from '../../model';
import {Tag} from '../atoms';
import {formatDateUTC} from "../../utils/lib";

type EventLogRowProps = {
    eventLog: EventLog;
}

export const EventLogRow = ({eventLog}: EventLogRowProps) => {
    return (
        <tr className="h-12">
            <td>
                <Tag type={TagType.Neutral}>{eventLog.eventCode}</Tag>
            </td>
            <td>{eventLog.description} </td>
            <td> {formatDateUTC(eventLog.eventTime, 'long')}</td>
            <td>{eventLog.userId}</td>
        </tr>
    );
};
