import {useQuery} from "@tanstack/react-query";
import {InvoiceApi} from "../../../api";
import {InvoiceStatus} from "../../../components";
import {formatDateUTC} from "../../../utils/lib";
import {useParams} from "react-router-dom";

export const InvoiceEvents = () => {
    const {id} = useParams();

    const {data: events = []} = useQuery({
        queryKey: [InvoiceApi.KEY, 'getEvents', id],
        queryFn: () => InvoiceApi.getEvents(id!),
        enabled: Boolean(id),
    });

    if (!events.length ) return null;

    return (
            <>
                <hr className="my-6 border-slate-100"/>
                <h2 className="mb-5 text-sm uppercase text-brand-6 font-semibold">History of status
                    changes</h2>
                <ul className="grid gap-4">
                    {events?.map((event) => (
                        <li className="flex items-center gap-7" key={event.statusDate}>
                            <span className="text-brand-2 text-xs">
                              {formatDateUTC(event.statusDate, 'long')}
                            </span>
                            <InvoiceStatus status={event.status}/>
                        </li>
                    ))}
                </ul>
            </>
    )
}