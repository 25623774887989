import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {SupportApi} from "../../../api";
import {Invoice, TagType} from "../../../model";
import {Button, Tag} from "../../../components";
import {Disclosure} from "@headlessui/react";
import cx from "classnames";
import {ChevronUpIcon} from "@heroicons/react/16/solid";
import {formatDateUTC} from "../../../utils/lib";
import {toast} from "react-toastify";
import {useProfile} from "../../../hooks";

export const InvoiceSupportIssues = ({invoice}: { invoice: Invoice }) => {
    const profile = useProfile();
    const queryClient = useQueryClient();
    const canChangeIssueStatus = !!profile?.permissions.includes('ticket.decision');

    const {data: supportIssues = []} = useQuery({
        queryKey: [SupportApi.KEY, 'getIssuesByInvoice', invoice?.invoiceId],
        queryFn: () => SupportApi.getIssuesByInvoice(invoice!.invoiceId),
        enabled: Boolean(invoice),
    });

    const supportIssueMutationApprove = useMutation({
        mutationFn: (ticketId: string) => SupportApi.approve({ticketId}),
        onSuccess() {
            toast.success('Successfully approved');
            queryClient.refetchQueries([SupportApi.KEY, 'getIssuesByInvoice', invoice?.invoiceId]);
        },
        onError() {
            toast.error('Failed to change status');
        }
    });

    const supportIssueMutationReject = useMutation({
        mutationFn: (ticketId: string) => SupportApi.reject({ticketId}),
        onSuccess() {
            toast.success('Successfully rejected');
            queryClient.refetchQueries([SupportApi.KEY, 'getIssuesByInvoice', invoice?.invoiceId]);
        },
        onError() {
            toast.error('Failed to change status');
        }
    });

    if (!supportIssues.length) return null;

    return (
        <Disclosure>
            {({open}) => (
                <div className={cx(
                    'p-7 w-3/5 bg-white rounded-3xl'
                )}>
                    <Disclosure.Button
                        as={'h2'}
                        className={
                            cx(
                                'flex justify-between text-sm uppercase font-semibold cursor-pointer text-green-500',
                                open && 'mb-5'
                            )}
                    >
                        <span>Support History ({supportIssues?.length})</span>
                        <ChevronUpIcon
                            className={cx(
                                open ? 'rotate-180 transform' : '',
                                'text-green-500',
                                'h-5 w-5'
                            )}
                        />
                    </Disclosure.Button>
                    <Disclosure.Panel className="grid gap-4">
                        <table
                            className="min-w-full text-sm font-light text-surface w-5/5">
                            <thead className="border-b border-neutral-200 font-medium">
                            <tr>
                                <th scope="col" className="pb-5 uppercase text-start">ID</th>
                                <th scope="col" className="pb-5 uppercase text-start">description</th>
                                <th scope="col" className="pb-5 uppercase text-start">creation date</th>
                                <th scope="col" className="pb-5 uppercase text-start">status</th>
                                {canChangeIssueStatus &&
                                    <th scope="col" className="pb-5 uppercase text-start">actions</th>}
                            </tr>
                            </thead>
                            <tbody>
                            {supportIssues.length > 0 && supportIssues.map((issue) => {
                                const isActionsBlocked = issue.status.code === 'REJECTED' || issue.status.code === 'SOLVED';
                                return (
                                    <tr className="h-12 border-b">
                                        <td>{issue.id}</td>
                                        <td>{issue.description} </td>
                                        <td>{formatDateUTC(issue.createDate)}</td>
                                        <td><Tag
                                            type={TagType[issue.status.code]}>{(issue.status.value.split('.').at(-1))!.replace('_', ' ')}</Tag>
                                        </td>
                                        {canChangeIssueStatus && (
                                            <th scope="col" className="uppercase text-center pt-1">
                                                {isActionsBlocked
                                                    ? ''
                                                    : (
                                                        <div className="flex flex-col gap-1">
                                                            <Button
                                                                className="cursor-pointer bg-transparent px-2 py-1"
                                                                onClick={() => supportIssueMutationApprove.mutate(issue.id)}
                                                                disabled={supportIssueMutationApprove.isLoading}>
                                                                <Tag className="size-full flex-center"
                                                                     type={TagType.SOLVED}>approve</Tag>
                                                            </Button>
                                                            <Button
                                                                className="cursor-pointer bg-transparent px-2 py-1"
                                                                onClick={() => supportIssueMutationReject.mutate(issue.id)}
                                                                disabled={supportIssueMutationReject.isLoading}>
                                                                <Tag className="size-full flex-center"
                                                                     type={TagType.REJECTED}>reject</Tag>
                                                            </Button>
                                                        </div>
                                                    )}
                                            </th>
                                        )}
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </Disclosure.Panel>
                </div>
            )}
        </Disclosure>
    )
}