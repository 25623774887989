import {Disclosure} from "@headlessui/react";
import cx from "classnames";
import {ChevronUpIcon} from "@heroicons/react/16/solid";
import {formatDateUTC} from "../../../utils/lib";
import {useQuery} from "@tanstack/react-query";
import {InvoiceApi} from "../../../api";
import {useParams} from "react-router-dom";
import {useProfile} from "../../../hooks";

export const InvoiceErrors = () => {
    const profile = useProfile();
    const {id} = useParams();
    const isErrorsVisible = profile?.permissions.includes('invoice.error.read');

    const {data: errors} = useQuery({
        queryKey: [InvoiceApi.KEY, 'event', id],
        queryFn: () => InvoiceApi.getErrors(id!),
        enabled: isErrorsVisible && !!id,
    });

    if(!isErrorsVisible) return null

    return (
        <Disclosure>
            {({open}) => (
                <div className={cx(
                    'p-7 w-3/5 bg-white rounded-3xl',
                )}>
                    <Disclosure.Button
                        as={'h2'}
                        className={
                            cx(
                                'flex justify-between text-sm uppercase text-brand-6 font-semibold cursor-pointer',
                                errors?.length ? 'text-rose-500' : 'text-green-500',
                                open && 'mb-5',
                            )}
                    >
                        <span>Errors ({errors?.length})</span>
                        <ChevronUpIcon
                            className={cx(
                                open ? 'rotate-180 transform' : '',
                                errors?.length ? 'text-rose-500' : 'text-green-500',
                                'h-5 w-5',
                            )}
                        />
                    </Disclosure.Button>
                    <Disclosure.Panel className="grid gap-4">
                        {errors && errors.length
                            ? errors.map(error => {
                                return (
                                    <div className="grid gap-4 text-xs">
                                        <hr className="border-slate-100"/>
                                        <span className="w-full text-brand-2 m-0">{error.description}</span>
                                        <span className="w-full self-end m-0">
                                                    {formatDateUTC(error.eventTime, 'long')}
                                                  </span>
                                    </div>
                                );
                            })
                            : (
                                <div className="grid gap-4 text-xs">
                                    <hr className="border-slate-100"/>
                                    <span className="w-full text-brand-2 m-0">No errors</span>
                                </div>
                            )}
                    </Disclosure.Panel>
                </div>
            )}
        </Disclosure>
    )
}