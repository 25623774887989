export enum TagType {
    Error = 'error',
    Neutral = 'neutral',
    Primary = 'primary',
    Success = 'success',
    Warning = 'warning',
    NEW = 'NEW',
    IN_WORK = 'IN_WORK',
    PENDING = 'PENDING',
    SOLVED = 'SOLVED',
    REJECTED = 'REJECTED'
}