import classNames from 'classnames';
import type { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { TagType } from '../../model';

interface Props extends HTMLAttributes<HTMLSpanElement> {
  type: TagType;
}

export const Tag: FC<PropsWithChildren<Props>> = ({ children, className, style, type, ...rest }) => {
  let bgColor = '';
  let borderColor = '';
  let textColor = '';

  switch (type) {
    case TagType.Primary:
    case TagType.IN_WORK:
      bgColor = '#F0F7FF';
      borderColor = '#DEEDFE';
      textColor = '#2E6FB8';
      break;
    case TagType.Error:
    case TagType.REJECTED:
      bgColor = '#FFF5F4';
      borderColor = '#F6E8E8';
      textColor = '#F34649';
      break;
    case TagType.Warning:
    case TagType.PENDING:
      bgColor = '#FFF9F2';
      borderColor = '#FBF4E8';
      textColor = '#FEBA39';
      break;
    case TagType.Success:
    case TagType.SOLVED:
      bgColor = '#F0FAF5';
      borderColor = '#E7F4EB';
      textColor = '#46C874';
      break;
    case TagType.Neutral:
    case TagType.NEW:
      bgColor = '#E0E0E0';
      borderColor = '#BDBDBD';
      textColor = '#4F4F4F';
      break;
  }

  return (
    <span
      {...rest}
      className={classNames(`text-xs font-semibold rounded-md border px-1 py-0.5`, className)}
      style={{
        ...style,
        color: textColor,
        backgroundColor: bgColor,
        borderColor,
      }}
    >
      {children}
    </span>
  );
};
