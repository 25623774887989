import {Disclosure} from "@headlessui/react";
import cx from "classnames";
import {ChevronUpIcon} from "@heroicons/react/16/solid";
import {EventLogRow} from "../../../components";
import {useProfile} from "../../../hooks";
import {useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {InvoiceApi} from "../../../api";

export const InvoiceEventsLogsBlock = () => {
    const profile = useProfile();
    const {id} = useParams();
    const isInvoiceEventsVisible = profile?.permissions.includes('invoice.event.read');

    const {data: eventLogs} = useQuery({
        queryKey: [InvoiceApi.KEY, 'getEventLogs', id],
        queryFn: () => InvoiceApi.getEventLogs(id!),
        enabled: Boolean(id)
    })

    if (!isInvoiceEventsVisible) return null

    return (
        <Disclosure>
            {({open}) => (
                <div className={cx(
                    'p-7 w-3/5 bg-white rounded-3xl',
                )}>
                    <Disclosure.Button
                        as={'h2'}
                        className={
                            cx(
                                'flex justify-between text-sm uppercase font-semibold cursor-pointer text-green-500',
                                open && 'mb-5',
                            )}
                    >
                        <span>Event Log ({eventLogs?.length})</span>
                        <ChevronUpIcon
                            className={cx(
                                open ? 'rotate-180 transform' : '',
                                'text-green-500',
                                'h-5 w-5',
                            )}
                        />
                    </Disclosure.Button>
                    <Disclosure.Panel className="grid gap-4">
                        <table
                            className="min-w-full text-sm font-light text-surface w-5/5">
                            <thead className="border-b border-neutral-200 font-medium">
                            <tr>
                                <th scope="col" className="pb-5 uppercase text-start">code</th>
                                <th scope="col" className="pb-5 uppercase text-start">description</th>
                                <th scope="col" className="pb-5 uppercase text-start">event time</th>
                                <th scope="col" className="pb-5 uppercase text-start">user id</th>
                            </tr>
                            </thead>
                            <tbody>
                            {eventLogs?.map((eventLog) => (
                                <EventLogRow key={eventLog.id} eventLog={eventLog}/>
                            ))}
                            </tbody>
                        </table>

                    </Disclosure.Panel>
                </div>
            )}
        </Disclosure>
    )
}