import {Button, Modal} from "../../../components";
import {normalizeAmount} from "../../../utils";
import {type ChangeEvent, useState} from "react";
import {Invoice} from "../../../model";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {InvoiceApi} from "../../../api";

export const InvoiceAmountChangeModal = (props: {
    invoice: Invoice
    isOpen: boolean
    setIsOpen: (arg: boolean) => void
}) => {
    const {id} = useParams();

    const [isOpenConfirm, setIsOpenConfirm] = useState(false);
    const [updatedAmount, setUpdatedAmount] = useState<string | null>(null);

    const queryClient = useQueryClient()

    const {mutate, isLoading} = useMutation({
        mutationFn: InvoiceApi.changeAmount,
        onSuccess() {
            setIsOpen(false);
            setIsOpenConfirm(false);
            queryClient.refetchQueries([InvoiceApi.KEY, 'getByMerchant', id]);
        },
        onError() {
            toast.error('Failed to change status');
        },
    });

    const {invoice, isOpen, setIsOpen} = props

    const onConfirm = () => {
        updatedAmount && id && mutate({
            invoiceId: id,
            amount: updatedAmount.split('.')[1] ? +String(updatedAmount).split('.').join('') : +`${updatedAmount}${'0'.repeat(invoice!.currency.dimension)}`,
        });
    };

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value.replace(/[^0-9.]/g, '');

        const dotCount = (value.match(/\./g) || []).length;
        if (dotCount > 1) {
            value = value.slice(0, value.indexOf('.') + 1) + value.slice(value.indexOf('.') + 1).replace(/\./g, '');
        }

        event.target.value = value;
        setUpdatedAmount(value);
    };

    const onChangeAmountSubmit = () => {
        if (updatedAmount) {
            let parts = updatedAmount.split('.');

            if (parts[1] && parts[1].length > 2) {
                toast.error('There must be max two decimals after comma')
                setUpdatedAmount(null);
                return
            }

            setIsOpenConfirm(true);
            return;
        }
        toast.error('Change the value');
    };


    return (
        <>
            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <div className="p-10">
                    <input
                        className="mt-1 max-h-80 input rounded-md p-2 w-full"
                        defaultValue={normalizeAmount(invoice.amount, invoice.currency)}
                        onChange={onChange}/>
                    <div className="mt-8 flex justify-center gap-4">
                        <Button onClick={() => setIsOpen(false)}>Cancel</Button>
                        <Button onClick={onChangeAmountSubmit}>Continue</Button>
                    </div>
                </div>
            </Modal>
            <Modal isOpen={isOpenConfirm} onClose={() => setIsOpenConfirm(false)}>
                <div className="p-6">
                    <h2 className="text-lg text-center">
                        Are you sure you want to change the amount to{' '}
                        <strong>{updatedAmount && updatedAmount.split('.')[1] ? updatedAmount : `${updatedAmount}.00`}</strong>?
                    </h2>
                    <div className="mt-8 flex justify-center gap-4">
                        <Button onClick={() => {
                            setIsOpenConfirm(false);
                        }}>Cancel</Button>
                        <Button onClick={onConfirm} disabled={isLoading}>Continue</Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}